import { IPlatformData } from 'interfaces/config/appConfig';
import yesPlatformText from 'constants/platformLabels/yesPlatformText';
import agbPlatformText from 'constants/platformLabels/agbPlatformText';
import YesPages from 'routes/manageRoute/platformRoutes/YesRoutes';
import AGBPages from 'routes/manageRoute/platformRoutes/AGBRoutes';
import requestOptions from './requestOptions';

export const Platform = {
  Yes: 'yes',
  MtoU: 'm2u',
  AGB: 'agb',
};

export const CURRENT_PLATFORM = process.env.REACT_APP_PLATFORM || '';

const platformData: IPlatformData = {
  [Platform.Yes]: {
    text: yesPlatformText,
    pages: YesPages,
    requestOption: requestOptions.OSSIXRequestItem,
  },

  [Platform.AGB]: {
    text: agbPlatformText,
    pages: AGBPages,
    requestOption: requestOptions.AGBRequestItem,
  },
  // Add configurations for other platforms (MtoU, etc.) here if needed
};

export const AppConfig = (
  platform: string = CURRENT_PLATFORM,
): IPlatformData => {
  return platformData[platform] || platformData[Platform.Yes];
};
