import { SVGProps } from 'react';
const WalletSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fill="currentColor"
        d="M22 7h1v10h-1v3a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v3Zm-2 10h-6a5 5 0 1 1 0-10h6V5H4v14h16v-2Zm1-2V9h-7a3 3 0 0 0 0 6h7Zm-7-4h3v2h-3v-2Z"
      />
    </g>
  </svg>
);
export default WalletSVG;
