import { SVGProps } from 'react';
const EyeCloseSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fill="currentColor"
        d="M17.882 19.297A10.95 10.95 0 0 1 12 21c-5.392 0-9.878-3.88-10.819-9a10.982 10.982 0 0 1 3.34-6.066L1.392 2.808l1.415-1.415 19.8 19.8-1.416 1.414-3.31-3.31h.001ZM5.935 7.35A8.965 8.965 0 0 0 3.223 12a9.006 9.006 0 0 0 13.201 5.838l-2.028-2.028A4.5 4.5 0 0 1 8.19 9.604L5.935 7.35Zm6.98 6.978-3.243-3.242a2.5 2.5 0 0 0 3.241 3.241l.001.001Zm7.892 2.264-1.43-1.43a8.938 8.938 0 0 0 1.4-3.162A9.006 9.006 0 0 0 9.552 5.338L7.974 3.76A10.99 10.99 0 0 1 12 3c5.392 0 9.878 3.88 10.82 9a10.949 10.949 0 0 1-2.013 4.592Zm-9.084-9.084a4.5 4.5 0 0 1 4.77 4.77l-4.77-4.77Z"
      />
    </g>
  </svg>
);
export default EyeCloseSVG;
