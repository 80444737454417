import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import {
  QueryClientProvider,
  QueryClient,
} from '@tanstack/react-query';
import PlatformProvider from 'hooks/PlatformContext';
import { LanguageProvider } from 'hooks/Language/useLanguage';
import { CURRENT_PLATFORM } from 'utils/config';
import agbIcon from 'assets/images/logoAGB.svg';

function setFavicon(iconUrl: string) {
  const link = document.createElement('link');
  link.rel = 'icon';
  link.href = iconUrl;
  document.getElementsByTagName('head')[0].appendChild(link);
}

document.title = CURRENT_PLATFORM || '';
setFavicon(CURRENT_PLATFORM === 'agb' ? agbIcon : '');

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

const queryClient = new QueryClient();

root.render(
  <QueryClientProvider client={queryClient}>
    <React.StrictMode>
      <PlatformProvider>
        <LanguageProvider>
          <App />
        </LanguageProvider>
      </PlatformProvider>
    </React.StrictMode>
  </QueryClientProvider>,
);
