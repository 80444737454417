/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import { platformRequestOption } from 'hooks/PlatformContext';
import { IApiRequest } from 'interfaces/apiRequestInterface';
import {
  getTokenFromLocalStorage,
  setTokenToLocalStorage,
} from 'utils/requestResponse';

const token = localStorage.getItem('userToken');
const refreshToken = localStorage.getItem('userRefreshToken');

const apiRequest = async ({ url, payload, method }: IApiRequest) => {
  const requestHeader = platformRequestOption.requestHeader({
    token: token,
  });
  const requestData = {
    ...platformRequestOption.requestData,
    ...(payload as { [key: string]: unknown }),
  };

  const postRequestOptions = {
    method: method,
    headers: requestHeader,
    data: JSON.stringify(requestData),
    url: `${platformRequestOption.url}/${url}`,
  };

  const fetchRequestOptions = {
    method: method,
    headers: requestHeader,
    url: `${platformRequestOption.url}/${url}`,
  };

  const config = ['POST', 'PUT', 'DELETE'].includes(method)
    ? postRequestOptions
    : fetchRequestOptions;

  const requestRefreshToken = {
    ...platformRequestOption.requestData,
    ...({ RefreshToken: refreshToken } as { [key: string]: unknown }),
  };

  const refreshTokenOptions = {
    method: 'POST',
    headers: requestHeader,
    data: JSON.stringify(requestRefreshToken),
    url: `${platformRequestOption.url}/RefreshToken`,
  };

  try {
    const response = await axios.request(config);
    return response;
  } catch (error: any) {
    if (error.response) {
      const errorData = error.response.data?.[0];
      if (
        errorData?.UserLoginCorrect === 'FALSE' &&
        errorData?.UserMustBeLoggedOut === 'Yes' &&
        errorData?.Error === '2041'
      ) {
        localStorage.removeItem('userToken');
        localStorage.removeItem('userRefreshToken');
        window.location.href = '/login';
      }

      if (
        errorData?.Error === '2034' ||
        errorData?.Error === '2041'
      ) {
        if (token && refreshToken) {
          const response = await axios.request(refreshTokenOptions);
          const { LiveToken, RefreshToken } = response.data[0];
          setTokenToLocalStorage('userToken', LiveToken);
          setTokenToLocalStorage('userRefreshToken', RefreshToken);
          const newLiveToken = getTokenFromLocalStorage('userToken');
          const newRequestHeader =
            platformRequestOption.requestHeader({
              token: newLiveToken,
            });
          const newConfig = {
            ...config,
            headers: newRequestHeader,
          };
          if (LiveToken) {
            const previousFailedRequest =
              await axios.request(newConfig);
            return previousFailedRequest;
          }
        }
      }
    } else if (error.request) {
      console.error('Error request-:', error.request);
    } else {
      console.error('Error message:', error.message);
    }
    throw error;
  }
};

export default apiRequest;
