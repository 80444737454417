import { SVGProps } from 'react';
const PlusSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fill="currentColor"
        d="M11 11V5h2v6h6v2h-6v6h-2v-6H5v-2h6Z"
      />
    </g>
  </svg>
);
export default PlusSVG;
