import { PageTypes } from 'types/pageType';
import { lazy } from 'react';

const Login = lazy(
  () => import('containers/bank/AGBBank/auth/LoginPageContainer'),
);
const Register = lazy(
  () => import('containers/bank/AGBBank/auth/RegisterPageContainer'),
);
const ForgotPassword = lazy(
  () => import('containers/bank/AGBBank/auth/ForgotPassword'),
);
const Dashboard = lazy(
  () => import('containers/bank/AGBBank/Dashboard'),
);
const Transactions = lazy(
  () => import('containers/bank/AGBBank/Transactions'),
);
const Settings = lazy(
  () => import('containers/bank/AGBBank/Settings'),
);

const Accounts = lazy(
  () => import('containers/bank/AGBBank/Accounts'),
);

const BankToBank = lazy(
  () => import('containers/bank/AGBBank/QuickTransfer/BankToBank'),
);

const BetweenOwnAccount = lazy(
  () => import('containers/bank/AGBBank/Transfer/BetweenOwnAccount'),
);

const InternationalTransfer = lazy(
  () =>
    import('containers/bank/AGBBank/Transfer/InternationalTransfer'),
);

const PayTax = lazy(
  () => import('containers/bank/AGBBank/QuickTransfer/PayTax'),
);

const Withdraw = lazy(
  () => import('containers/bank/AGBBank/QuickTransfer/Withdraw'),
);

const BeneficiaryPage = lazy(
  () => import('containers/bank/AGBBank/Beneficiary'),
);

const PayBillsPage = lazy(
  () => import('containers/bank/AGBBank/PayBills'),
);

const UserProfilePage = lazy(
  () => import('containers/bank/AGBBank/UserProfile'),
);

const MicroloanPageContainer = lazy(
  () => import('containers/bank/AGBBank/Microloan'),
);

const CardsPage = lazy(() => import('containers/bank/AGBBank/Cards'));
const TransferToMNOPage = lazy(
  () => import('containers/bank/AGBBank/QuickTransfer/TransferToMNO'),
);

const ApplyForLoanPage = lazy(
  () => import('containers/bank/AGBBank/Microloan/ApplyForLoan'),
);

const CashWithdrawPage = lazy(
  () => import('containers/bank/AGBBank/Accounts/CashWithdraw'),
);

const QuickPayPage = lazy(
  () => import('containers/bank/AGBBank/Dashboard/QuickPay'),
);

const PrepaidCardPage = lazy(
  () => import('containers/bank/AGBBank/QuickTransfer/PrepaidCard'),
);

const AGBPages: PageTypes = {
  LoginPage: <Login />,
  RegistrationPage: <Register />,
  ForgotPasswordPage: <ForgotPassword />,
  DashboardPage: <Dashboard />,
  Transactions: <Transactions />,
  Settings: <Settings />,
  Accounts: <Accounts />,
  BankToBank: <BankToBank />,
  LocalTransfer: <InternationalTransfer />,
  BetweenOwnAccount: <BetweenOwnAccount />,
  PayTax: <PayTax />,
  WithdrawPage: <Withdraw />,
  BeneficiaryPage: <BeneficiaryPage />,
  PayBillsPage: <PayBillsPage />,
  UserProfilePage: <UserProfilePage />,
  MicroloanPage: <MicroloanPageContainer />,
  CardsPage: <CardsPage />,
  TransferToMNOPage: <TransferToMNOPage />,
  ApplyForLoanPage: <ApplyForLoanPage />,
  CashWithdrawPage: <CashWithdrawPage />,
  QuickPayPage: <QuickPayPage />,
  PrepaidCardPage: <PrepaidCardPage />,
};

export default AGBPages;
