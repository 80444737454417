import ArrowLeftSVG from 'assets/icons/ArrowLeftSVG';
import ArrowRightSVG from 'assets/icons/ArrowRightSVG';
import BillsSVG from 'assets/icons/BillsSVG';
import BugSVG from 'assets/icons/BugSVG';
import CardSVG from 'assets/icons/CardSVG';
import CaretDownSVG from 'assets/icons/CaretDownSVG';
import {
  default as CaretLeftSVG,
  default as CaretRightSVG,
} from 'assets/icons/CaretRightSVG';
import CaretUpSVG from 'assets/icons/CaretUpSVG';
import CheckCircle from 'assets/icons/CheckCircleSVG';
import CheckSVG from 'assets/icons/CheckSVG ';
import ContactSVG from 'assets/icons/ContactSVG';
import CreditedSVG from 'assets/icons/CreditedSVG';
import DebitedSVG from 'assets/icons/DebitedSVG';
import DustbinSVG from 'assets/icons/DustbinSVG';
import EditSVG from 'assets/icons/EditSVG';
import EllipsisSVG from 'assets/icons/EllipsisSVG';
import ExchangeSVG from 'assets/icons/ExchangeSVG';
import ExternalLinkSVG from 'assets/icons/ExternalLinkSVG';
import EyeCloseSVG from 'assets/icons/EyeCloseSVG';
import EyeOpenSVG from 'assets/icons/EyeOpenSVG';
import FavoriteSVG from 'assets/icons/FavoriteSVG';
import GetPaidSVG from 'assets/icons/GetPaidSVG';
import GiftSVG from 'assets/icons/GiftSVG';
import HomeSVG from 'assets/icons/HomeSVG';
import LanguageSVG from 'assets/icons/LanguageSVG';
import LoanSVG from 'assets/icons/LoanSVG';
import MerchantSVG from 'assets/icons/MerchantSVG';
import MessageSVG from 'assets/icons/MessageSVG';
import MinusSVG from 'assets/icons/MinusSVG';
import NotificationSVG from 'assets/icons/NotificationSVG';
import OrganizationSVG from 'assets/icons/OrganizationSVG';
import PlusSVG from 'assets/icons/PlusSVG';
import QRCodeSVG from 'assets/icons/QRCodeSVG';
import QuickPaySVG from 'assets/icons/QuickPaySVG';
import RefreshSVG from 'assets/icons/RefreshSVG';
import RenameSVG from 'assets/icons/RenameSVG';
import SavingSVG from 'assets/icons/SavingSVG';
import ScanSVG from 'assets/icons/ScanSVG';
import SearchSVG from 'assets/icons/SearchSVG';
import SendCashSVG from 'assets/icons/SendCashSVG';
import ServicesSVG from 'assets/icons/ServicesSVG';
import SettingSVG from 'assets/icons/SettingSVG';
import TopupSVG from 'assets/icons/TopupSVG';
import TransactionSVG from 'assets/icons/TransactionSVG';
import TransferSVG from 'assets/icons/TransferSVG';
import UncheckCircleSVG from 'assets/icons/UncheckCircleSVG';
import UncheckSVG from 'assets/icons/UncheckSVG';
import UserSVG from 'assets/icons/UserSVG';
import WalletSVG from 'assets/icons/WalletSVG';
import WithdrawSVG from 'assets/icons/WithdrawSVG';
// import IconInterface from 'types/icons';

export default function IconComponent({ name = 'home' }: any) {
  let IconName = null;
  switch (name) {
    case 'bills':
      IconName = BillsSVG({ height: '1.2em', width: '1.2em' });
      break;
    case 'bug':
      IconName = BugSVG({ height: '1.2em', width: '1.2em' });
      break;
    case 'card':
      IconName = CardSVG({ height: '1.2em', width: '1.2em' });
      break;
    case 'contact':
      IconName = ContactSVG({ height: '1.2em', width: '1.2em' });
      break;
    case 'home':
      IconName = HomeSVG({ height: '1.2em', width: '1.2em' });
      break;
    case 'language':
      IconName = LanguageSVG({ height: '1.2em', width: '1.2em' });
      break;
    case 'loan':
      IconName = LoanSVG({ height: '1.4em', width: '1.4em' });
      break;
    case 'message':
      IconName = MessageSVG({ height: '1.2em', width: '1.2em' });
      break;
    case 'notification':
      IconName = NotificationSVG({ height: '1.2em', width: '1.2em' });
      break;
    case 'organization':
      IconName = OrganizationSVG({ height: '1.2em', width: '1.2em' });
      break;
    case 'saving':
      IconName = SavingSVG({ height: '1.2em', width: '1.2em' });
      break;
    case 'services':
      IconName = ServicesSVG({ height: '1.2em', width: '1.2em' });
      break;
    case 'setting':
      IconName = SettingSVG({ height: '1.2em', width: '1.2em' });
      break;
    case 'topup':
      IconName = TopupSVG({ height: '1.2em', width: '1.2em' });
      break;
    case 'transaction':
      IconName = TransactionSVG({ height: '1.2em', width: '1.2em' });
      break;
    case 'transfer':
      IconName = TransferSVG({ height: '1.2em', width: '1.2em' });
      break;
    case 'user':
      IconName = UserSVG({ height: '1.2em', width: '1.2em' });
      break;
    case 'wallet':
      IconName = WalletSVG({ height: '1.2em', width: '1.2em' });
      break;
    case 'caret-down':
      IconName = CaretDownSVG({ height: '1.2em', width: '1.2em' });
      break;
    case 'caret-up':
      IconName = CaretUpSVG({ height: '1.2em', width: '1.2em' });
      break;
    case 'caret-left':
      IconName = CaretLeftSVG({ height: '1.2em', width: '1.2em' });
      break;
    case 'caret-right':
      IconName = CaretRightSVG({ height: '1.2em', width: '1.2em' });
      break;
    case 'eye-open':
      IconName = EyeOpenSVG({ height: '1.2em', width: '1.2em' });
      break;
    case 'eye-close':
      IconName = EyeCloseSVG({ height: '1.2em', width: '1.2em' });
      break;
    case 'plus':
      IconName = PlusSVG({ height: '1.2em', width: '1.2em' });
      break;
    case 'merchant':
      IconName = MerchantSVG({ height: '1.2em', width: '1.2em' });
      break;
    case 'gift':
      IconName = GiftSVG({ height: '1.2em', width: '1.2em' });
      break;
    case 'scan':
      IconName = ScanSVG({ height: '1.2em', width: '1.2em' });
      break;
    case 'qrcode':
      IconName = QRCodeSVG({ height: '1.2em', width: '1.2em' });
      break;
    case 'minus':
      IconName = MinusSVG({ height: '1.2em', width: '1.2em' });
      break;
    case 'ellipsis':
      IconName = EllipsisSVG({ height: '1.2em', width: '1.2em' });
      break;
    case 'quick-pay':
      IconName = QuickPaySVG({ height: '1.2em', width: '1.2em' });
      break;
    case 'withdraw':
      IconName = WithdrawSVG({ height: '1.2em', width: '1.2em' });
      break;
    case 'get-paid':
      IconName = GetPaidSVG({ height: '1.2em', width: '1.2em' });
      break;
    case 'send-cash':
      IconName = SendCashSVG({ height: '1.2em', width: '1.2em' });
      break;
    case 'check-circle':
      IconName = CheckCircle({ height: '1.2em', width: '1.2em' });
      break;
    case 'uncheck-circle':
      IconName = UncheckCircleSVG({
        height: '1.2em',
        width: '1.2em',
      });
      break;
    case 'check':
      IconName = CheckSVG({ height: '1.2em', width: '1.2em' });
      break;
    case 'uncheck':
      IconName = UncheckSVG({ height: '1.2em', width: '1.2em' });
      break;
    case 'dustbin':
      IconName = DustbinSVG({ height: '1.2em', width: '1.2em' });
      break;
    case 'rename':
      IconName = RenameSVG({ height: '1.2em', width: '1.2em' });
      break;
    case 'exchange':
      IconName = ExchangeSVG({ height: '1.2em', width: '1.2em' });
      break;
    case 'refresh':
      IconName = RefreshSVG({ height: '1.2em', width: '1.2em' });
      break;
    case 'search':
      IconName = SearchSVG({ height: '1.2em', width: '1.2em' });
      break;
    case 'external-link':
      IconName = ExternalLinkSVG({ height: '1.2em', width: '1.2em' });
      break;
    case 'debited':
      IconName = DebitedSVG({ height: '1.2em', width: '1.2em' });
      break;
    case 'credited':
      IconName = CreditedSVG({ height: '1.2em', width: '1.2em' });
      break;
    case 'arrow-left':
      IconName = ArrowLeftSVG({ height: '1.2em', width: '1.2em' });
      break;
    case 'arrow-right':
      IconName = ArrowRightSVG({ height: '1.2em', width: '1.2em' });
      break;
    case 'edit':
      IconName = EditSVG({ height: '1.2em', width: '1.2em' });
      break;
    case 'favorite':
      IconName = FavoriteSVG({ height: '1.2em', width: '1.2em' });
      break;
    default:
      console.warn(`Unknown icon name: ${name}`);
  }

  return <>{IconName}</>;
}
