import useLogout from 'hooks/useLogout';
import { ISplitUrlLocation } from 'interfaces/routesInt';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import {
  Outlet,
  Navigate,
  useNavigate,
  useLocation,
} from 'react-router-dom';
import urls from 'routes/urls';

interface IAuthMiddleware {
  isAuthProtected: boolean;
}

const AuthMiddleware = ({ isAuthProtected }: IAuthMiddleware) => {
  const storageKey = 'appOpenTime';
  const navigate = useNavigate();
  const { onHandleLogout } = useLogout();

  const { pathname } = useLocation();
  const splitLocation: ISplitUrlLocation = {
    segments: pathname.split('/'),
  };

  useEffect(() => {
    const initialTime = Date.now();
    localStorage.setItem(storageKey, initialTime.toString());

    const onStorageChange = (event: StorageEvent) => {
      if (
        event.key === storageKey &&
        event.oldValue !== initialTime.toString()
      ) {
        onHandleLogout();
      }
    };

    window.addEventListener('storage', onStorageChange);
    return () =>
      window.removeEventListener('storage', onStorageChange);
  }, []);

  useEffect(() => {
    const userToken = localStorage.getItem('userToken');
    if (!userToken) {
      navigate('/login');
    } else {
      if (splitLocation?.segments === urls.dashboard) {
        navigate(urls.dashboard);
      }
    }
  }, [navigate, splitLocation]);

  return isAuthProtected === true ? (
    <Outlet />
  ) : (
    <Navigate to={'/login'} />
  );
};

AuthMiddleware.prototype = {
  isAuthProtected: PropTypes.bool,
};

export default AuthMiddleware;
