import { SVGProps } from 'react';
const EyeOpenSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fill="currentColor"
        d="M12 3c5.392 0 9.878 3.88 10.82 9-.94 5.12-5.428 9-10.82 9s-9.878-3.88-10.819-9c.94-5.12 5.427-9 10.82-9Zm0 16a9.005 9.005 0 0 0 8.777-7 9.005 9.005 0 0 0-17.554 0A9.005 9.005 0 0 0 12 19Zm0-2.5a4.5 4.5 0 1 1 0-9 4.5 4.5 0 0 1 0 9Zm0-2a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z"
      />
    </g>
  </svg>
);
export default EyeOpenSVG;
