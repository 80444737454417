export default {
  // Users
  AddWallet: 'AddWallet',
  SaveUserLanguageChoice: 'SaveUserLanguageChoice',
  CheckUserPIN: 'CheckUserPIN',
  GetCountryCurrencies: 'GetCountryCurrencies',
  notifications: 'notifications',
  DeleteWallet: 'DeleteWallet',
  RenameWallet: 'RenameWallet',
  GetCurrenciesList: 'GetCurrenciesList',
  GetUserNetWorth: 'GetUserNetWorth',
  LoadLanguage: 'LoadLanguage',
  GetUserWalletList: 'GetUserWalletList',
  GetProfessionList: 'GetProfessionList',
  SupportedLanguages: 'SupportedLanguages',
  UserCurrencyList: 'UserCurrencyList',
  GetUserData: 'GetUserData',
  TransactionsGraphData: 'TransactionsGraphData',
  CheckUserCredential: 'CheckUserCredential',
  GetUserLoginStatus: 'GetUserLoginStatus',
  LogOff: 'LogOff',
  RefreshToken: 'RefreshToken',
  CreateFullUserAccount: 'CreateFullUserAccount',
  ResetUserPasswordAndPIN: 'ResetUserPasswordAndPIN',
  ResetPasswordPrequalification: 'ResetPasswordPrequalification',
  SaveUserIDdata: 'SaveUserIDdata',
  users: 'users',
  SendPhoneVerifCode: 'SendPhoneVerifCode',
  SetAsDefault: 'SetAsDefault',
  SetUserPresenceStatus: 'SetUserPresenceStatus',
  GetLocationData: 'GetLocationData',
  GetUserSecurityQuestions: 'GetUserSecurityQuestions',
  EMailVerifCallBack: 'EMailVerifCallBack',
  PhoneVerifResult: 'PhoneVerifResult',
  PhoneNumberExists: 'PhoneNumberExists',
  UserPIDExists: 'UserPIDExists',
  // VirtualCard
  AddMoneyToVirtualCard: 'AddMoneyToVirtualCard',
  AddVirtualCard: 'AddVirtualCard',
  GetVirtualCardList: 'GetVirtualCardList',
  RedeemVirtualCardBalance: 'RedeemVirtualCardBalance',
  RenewVirtualCard: 'RenewVirtualCard',
  UpdateVirtualCardStatus: 'UpdateVirtualCardStatus',
  // Vouchers
  StoreRejectVoucher: 'StoreRejectVoucher',
  MNOCountryList: 'MNOCountryList',
  GetStorePendingVoucher: 'GetStorePendingVoucher',
  RedeemVoucher: 'RedeemVoucher',
  SearchStore: 'SearchStore',
  VerifyVoucher: 'VerifyVoucher',
  // Wallets
  BankAccountToWallet: 'BankAccountToWallet',
  GetBankList: 'GetBankList',
  GetLinkedAccountList: 'GetLinkedAccountList',
  UserLinkBankAccount: 'UserLinkBankAccount',
  LinkBankAccountRequest: 'LinkBankAccountRequest',
  SelfLinkBankAccount: 'SelfLinkBankAccount',
  WalletToBankAccount: 'WalletToBankAccount',
  UnLinkBankAccount: 'UnLinkBankAccount',
  // AddMoney
  AddMoneyFromCreditCard: 'AddMoneyFromCreditCard',
  RequestMoneyFromGateway: 'RequestMoneyFromGateway',
  GatewayAddMoneyFees: 'GatewayAddMoneyFees',
  CardOperationFees: 'CardOperationFees',
  RemittanceAddMoneyFees: 'RemittanceAddMoneyFees',
  // Auth
  GetEventURLs: 'GetEventURLs',
  AddOrRemoveFavorite: 'AddOrRemoveFavorite',
  BlockContact: 'BlockContact',
  GetBlockingContacts: 'GetBlockingContacts',
  BlockedContactList: 'BlockedContactList',
  GetAllContactList: 'GetAllContactList',
  GetExternalContactList: 'GetExternalContactList',
  GetFavoriteList: 'GetFavoriteList',
  GetLastTransactionContacts: 'GetLastTransactionContacts',
  GetLastTransactionExternalContacts:
    'GetLastTransactionExternalContacts',
  GetRefereesList: 'GetRefereesList',
  LocateUser: 'LocateUser',
  LocateWallet: 'LocateWallet',
  SaveContactBankAccount: 'SaveContactBankAccount',
  AddToExternalContact: 'AddToExternalContact',
  OssixCountries: 'OssixCountries',
  // Credit card
  CreditCardReceived: 'CreditCardReceived',
  ChangeCardPIN: 'ChangeCardPIN',
  AddCreditCard: 'AddCreditCard',
  RemoveCreditCard: 'RemoveCreditCard',
  EnableCreditCard: 'EnableCreditCard',
  GetCreditCardList: 'GetCreditCardList',
  GetCreditCardOptions: 'GetCreditCardOptions',
  // Activity sector list
  GetActivitySectorList: 'GetActivitySectorList',
  ApplyForMicroLoan: 'ApplyForMicroLoan',
  CheckForLoanEligibility: 'CheckForLoanEligibility',
  PayLoanConfirmation: 'PayLoanConfirmation',
  GetLoanList: 'GetLoanList',
  PayLoan: 'PayLoan',
  // Money Transfer
  GetWayCheckout: 'api/v1/gateway/checkout',
  CashBackRequest: 'CashBackRequest',
  TransferConfirmation: 'TransferConfirmation',
  GatewayTransferConfirmation: 'GatewayTransferConfirmation',
  ModifyCash: 'ModifyCash',
  SendFundsToGateway: 'SendFundsToGateway',
  TransferFundsInternal: 'TransferFundsInternal',
  TransferToOther: 'TransferToOther',
  // Organization
  AddBulkPaymentRecipients: 'AddBulkPaymentRecipients',
  AddHelper: 'AddHelper',
  CalcBulkPaymentOps: 'CalcBulkPaymentOps',
  CreateABulkPayment: 'CreateABulkPayment',
  EnrollUpdateBulkOrganization: 'EnrollUpdateBulkOrganization',
  DuplicateBulkPaymentOps: 'DuplicateBulkPaymentOps',
  ExecuteBulkPaymentOps: 'ExecuteBulkPaymentOps',
  GetBulkPaymentDetails: 'GetBulkPaymentDetails',
  GetBulkPaymentList: 'GetBulkPaymentList',
  BulkPaymentTemplate: 'BulkPaymentTemplate',
  GetHelpersList: 'GetHelpersList',
  GeOrganizationsList: 'GeOrganizationsList',
  SavingsKPI: 'SavingsKPI',
  ModifyBulkPaymentRecipient: 'ModifyBulkPaymentRecipient',
  // Pay Bills
  GetBillsCategoriesList: 'GetBillsCategoriesList',
  GetSuppliers: 'GetSuppliers',
  GetSuppliersCountryList: 'GetSuppliersCountryList',
  PayInvoice: 'PayInvoice',
  VerifyGatewayAccount: 'VerifyGatewayAccount',
  // Peer Services
  PSAddComment: 'PSAddComment',
  PSAddRemoveBookMark: 'PSAddRemoveBookMark',
  PSAddUpdateService: 'PSAddUpdateService',
  PSDeleteComment: 'PSDeleteComment',
  PSGetBookmarkList: 'PSGetBookmarkList',
  PSGetCategories: 'PSGetCategories',
  PSGetServiceList: 'PSGetServiceList',
  ReportAbuse: 'ReportAbuse',
  //  Providers
  MNOList: 'MNOList',
  // Publicity
  CreateUpdateADCampaign: 'CreateUpdateADCampaign',
  CalcCampaignCost: 'CalcCampaignCost',
  DeleteADCampaign: 'DeleteADCampaign',
  ExecuteCampaign: 'ExecuteCampaign',
  GetCampaignList: 'GetCampaignList',
  UpdateCampaignViewsCount: 'UpdateCampaignViewsCount',
  AddSponsoree: 'AddSponsoree',
  RedeemReferralPayment: 'RedeemReferralPayment',
  // Remind Username
  GetUserPID: 'GetUserPID',
  // Savings
  ReturnSavingsDurationArray: 'ReturnSavingsDurationArray',
  GetSavingsList: 'GetSavingsList',
  SavingsCancelConfirmation: 'SavingsCancelConfirmation',
  CancelSavingsPlan: 'CancelSavingsPlan',
  ExecuteSavingsPlan: 'ExecuteSavingsPlan',
  SavingsTransferConfirmation: 'SavingsTransferConfirmation',
  // Send Email
  SendEmail: 'SendEmail',
  // Stores
  AddStoreAgent: 'AddStoreAgent',
  AddUpdateStore: 'AddUpdateStore',
  DeleteStore: 'DeleteStore',
  GetStoreComments: 'GetStoreComments',
  GetMerchantTypeList: 'GetMerchantTypeList',
  GetUserStoreList: 'GetUserStoreList',
  GetStoreCategoryList: 'GetStoreCategoryList',
  GetStoreAgentsList: 'GetStoreAgentsList',
  AddCommentToStore: 'AddCommentToStore',
  SetStoreStatus: 'SetStoreStatus',
  UploadBusinessPicture: 'UploadBusinessPicture',
  UploadKYCDocs: 'UploadKYCDocs',
  // Transactions
  TransactionsHistoryLight: 'TransactionsHistoryLight',
  UpdateTransferToOther: 'UpdateTransferToOther',
  CancelCash: 'CancelCash',
  CancelVoucher: 'CancelVoucher',
  DisputeTransaction: 'DisputeTransaction',
  GetCashTransactions: 'GetCashTransactions',
  TransactionsHistory: 'TransactionsHistory',
  GetPendingOtherTransfer: 'GetPendingOtherTransfer',
  GetPendingReceivedVoucherList: 'GetPendingReceivedVoucherList',
  GetPendingVoucherList: 'GetPendingVoucherList',
  GetUnpaidCashList: 'GetUnpaidCashList',
  GetTransaction: 'GetTransactions',
  RecallTransaction: 'RecallTransaction',
  GetRecentStoreList: 'GetRecentStoreList',
  GetAccountTransactionsSummary: 'GetAccountTransactionsSummary',

  // User Account Management
  UpdateUserPhoneList: 'UpdateUserPhoneList',
  GetProfessionTypeList: 'GetProfessionTypeList',
  SaveUserData: 'SaveUserData',
  UpdateBusinessAccountData: 'UpdateBusinessAccountData',
  SetUserDateOfBirth: 'SetUserDateOfBirth',
  SetUserGender: 'SetUserGender',
  ChangeUserPwd: 'ChangeUserPwd',
  ChangeUserPIN: 'ChangeUserPIN',
  UpdateUserSecurityQuestions: 'UpdateUserSecurityQuestions',
  UpdateUserEmailList: 'UpdateUserEmailList',
  GetSavedBankAccountList: 'GetSavedBankAccountList',
  SaveBankAccountContact: 'SaveBankAccountContact',
  SaveGatewayContact: 'SaveGatewayContact',
  GetSavedGatewayContactList: 'GetSavedGatewayContactList',

  // Account
  GetLinkedAccountBalance: 'GetLinkedAccountBalance',
};
