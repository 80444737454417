import React, { Suspense } from 'react';
import { useLocation } from 'react-router-dom';
import { IPageRoute, ISplitUrlLocation } from 'interfaces/routesInt';
import GenerateRoutes from '../generateRoute/GenerateRoutes';
import urls from '../urls';
import { usePage } from 'hooks/PlatformContext';
import LoadingComponent from 'components/shared/Loading';

const formatUrl = (url: string) => url.slice(1);

const ManageRoutes: React.FC = () => {
  const { pathname } = useLocation();

  const splitLocation: ISplitUrlLocation = {
    segments: pathname.split('/'),
  };

  const LoginPage = usePage('LoginPage');
  const RegisterPage = usePage('RegistrationPage');
  const DashboardPage = usePage('DashboardPage');
  const ForgotPasswordPage = usePage('ForgotPasswordPage');
  const RemindUsernamePage = usePage('RemindUsernamePage');
  const Transactions = usePage('Transactions');
  const Settings = usePage('Settings');
  const Accounts = usePage('Accounts');
  const BankToBank = usePage('BankToBank');
  const LocalTransfer = usePage('LocalTransfer');
  const BetweenOwnAccount = usePage('BetweenOwnAccount');
  const PayTax = usePage('PayTax');
  const WithdrawPage = usePage('WithdrawPage');
  const BeneficiaryPage = usePage('BeneficiaryPage');
  const PayBillsPage = usePage('PayBillsPage');
  const UserProfilePage = usePage('UserProfilePage');
  const MicroloanPage = usePage('MicroloanPage');
  const CardsPage = usePage('CardsPage');
  const TransferToMNOPage = usePage('TransferToMNOPage');
  const ApplyForLoanPage = usePage('ApplyForLoanPage');
  const CashWithdrawPage = usePage('CashWithdrawPage');
  const QuickPayPage = usePage('QuickPayPage');
  const PrepaidCardPage = usePage('PrepaidCardPage');

  const pages: IPageRoute = {
    [formatUrl(urls.landingPage)]: LoginPage,
    [formatUrl(urls.login)]: LoginPage,
    [formatUrl(urls.register)]: RegisterPage,
    [formatUrl(urls.dashboard)]: DashboardPage,
    [formatUrl(urls.forgotPassword)]: ForgotPasswordPage,
    [formatUrl(urls.remindUsername)]: RemindUsernamePage,
    [formatUrl(urls.transactions)]: Transactions,
    [formatUrl(urls.settings)]: Settings,
    [formatUrl(urls.accounts)]: Accounts,
    [formatUrl(urls.bankToBank)]: BankToBank,
    [formatUrl(urls.localTransfer)]: LocalTransfer,
    [formatUrl(urls.transferBetweenOwnAccount)]: BetweenOwnAccount,
    [formatUrl(urls.payTax)]: PayTax,
    [formatUrl(urls.withdraw)]: WithdrawPage,
    [formatUrl(urls.beneficiary)]: BeneficiaryPage,
    [formatUrl(urls.payBills)]: PayBillsPage,
    [formatUrl(urls.userProfile)]: UserProfilePage,
    [formatUrl(urls.microloan)]: MicroloanPage,
    [formatUrl(urls.cards)]: CardsPage,
    [formatUrl(urls.operators)]: TransferToMNOPage,
    [formatUrl(urls.applyForLoan)]: ApplyForLoanPage,
    [formatUrl(urls.cashWithdraw)]: CashWithdrawPage,
    [formatUrl(urls.quickPay)]: QuickPayPage,
    [formatUrl(urls.prepaidCard)]: PrepaidCardPage,
  };

  return (
    <Suspense fallback={<LoadingComponent />}>
      <GenerateRoutes
        splitLocation={splitLocation}
        pageRoute={pages}
      />
    </Suspense>
  );
};

export default ManageRoutes;
