const API_KEY = process.env.REACT_APP_API_KEY;
const APP_ID = process.env.REACT_APP_ID;
const LOGIN_NAME = process.env.REACT_APP_LOGIN_NAME;
const URL_API = process.env.REACT_APP_API_URL;

const OSSIXRequestItem = {
  requestHeader: ({ token }: { token?: string }) => {
    return {
      'Content-Type': 'application/json',
      ...(token ? { From: token } : {}),
    };
  },
  requestData: {
    APIKey: API_KEY,
    AppID: APP_ID,
    LoginName: LOGIN_NAME,
  },
  url: URL_API,
};

export default {
  OSSIXRequestItem,
  AGBRequestItem: OSSIXRequestItem,
  // Add platform config
};
