import { SVGProps } from 'react';
const QuickPaySVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fill="currentColor"
        d="M15 3h6v5h-2V5h-4V3ZM9 3v2H5v3H3V3h6Zm6 18v-2h4v-3h2v5h-6Zm-6 0H3v-5h2v3h4v2ZM3 11h18v2H3v-2Z"
      />
    </g>
  </svg>
);
export default QuickPaySVG;
