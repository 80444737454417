import { ConfigProvider } from 'antd';
import './assets/styles/index.scss';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import routes from 'routes';
import AuthMiddleware from 'middlewares/authMiddleware';
import PageNotFound from 'components/shared/Error';
import { IRoute } from 'interfaces/routesInt';
import { usePlatformConfig } from 'hooks/PlatformContext';
import connect from 'services/socket';
import wallet from 'services/wallet';
import failedOperation from 'services/failedOperation';

function App() {
  const platformConfig = usePlatformConfig();
  const backgroundColor = platformConfig.primaryColor;

  connect();
  wallet();
  failedOperation();

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: backgroundColor,
        },
      }}
    >
      <div className="App">
        <BrowserRouter basename="/">
          <Routes>
            {routes.map((route: IRoute, index: number) => {
              return route.isProtected ? (
                <Route
                  key={index}
                  element={
                    <AuthMiddleware
                      isAuthProtected={route.isProtected}
                    />
                  }
                >
                  <Route
                    path={route.path}
                    key={route.key}
                    index={route.index}
                    element={route.element}
                  />
                </Route>
              ) : (
                <Route
                  path={route.path}
                  key={route.key}
                  index={route.index || true}
                  element={route.element}
                />
              );
            })}
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </BrowserRouter>
      </div>
    </ConfigProvider>
  );
}

export default App;
