/* eslint-disable @typescript-eslint/no-empty-function */
import { getUserData } from 'queries/ossix/user/userData';
import { useEffect } from 'react';
import { connectUserEvent } from './connectUser';
import socketIOClient from './socketIO';

export const WELCOME = 'Welcome';
export const CONNECTED = 'connected';
export const CONNECT_USER = 'ConnectUser';
export const CONNECT_USER_SUCCESS = 'ConnectUserSuccess';
export const CONNECT_USER_ERROR = 'ConnectUserError';
export const RECONNECT = 'reconnect';

export default () => {
  const { userData, isLoading } = getUserData();

  const data = userData?.[0];

  useEffect(() => {
    if (!isLoading && data && Object.keys(data).length) {
      connectUserEvent({
        PID: data.PID,
        Country: data.Country,
      });
      socketIOClient.off(CONNECT_USER_SUCCESS);
      socketIOClient.on(CONNECT_USER_SUCCESS, () => {});
      socketIOClient.off(CONNECT_USER_ERROR);
      socketIOClient.on(CONNECT_USER_ERROR, () => {});
    }
    return () => {
      socketIOClient.off(CONNECT_USER_SUCCESS);
      socketIOClient.off(CONNECT_USER_ERROR);
    };
  }, [data && data.PID]);
};
