import { SVGProps } from 'react';
const CaretRightSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    {...props}
  >
    <g clipPath="url(#clip0_13_10493)">
      <path
        d="M13.1722 12L8.22217 7.04999L9.63617 5.63599L16.0002 12L9.63617 18.364L8.22217 16.95L13.1722 12Z"
        fill="currentColor"
      />
    </g>
  </svg>
);
export default CaretRightSVG;
