import { postMutate } from 'utils/customMutation';
import { fetchData } from 'utils/customUseQuery';
import endPoints from 'utils/platformEndPoints/ossix';

export const getUserData = () => {
  const { data, isLoading } = fetchData({
    url: endPoints.GetUserData,
  });
  return { isLoading, userData: data ?? [] };
};

export const getAllUserContact = () => {
  const { pureData, isPending, isError, isSuccess, message, mutate } =
    postMutate(endPoints.GetSavedGatewayContactList);
  return {
    isLoadingContacts: isPending,
    userContactData: pureData,
    mutate,
    isRequestSavedContactSuccess: isSuccess,
    isRequestSavedContactError: isError,
    requestSavedContactMessage: message,
  };
};

export const getUserContact = (payload: { CountryCode: string }) => {
  const { data, isPending, isError, isSuccess } = fetchData({
    url: endPoints.GetSavedGatewayContactList,
    payload: payload,
    enabled: !!payload.CountryCode,
  });
  return {
    isLoadingContacts: isPending,
    userContactData: data,
    isRequestSavedContactSuccess: isSuccess,
    isRequestSavedContactError: isError,
  };
};

export const saveBankAccountContact = () => {
  const { data, isPending, isError, isSuccess, message, mutate } =
    postMutate(endPoints.SaveBankAccountContact);
  return {
    isRequestLoading: isPending,
    savedUserContactData: data,
    mutate,
    isSaveUserContactSuccess: isSuccess,
    isSaveUserContactError: isError,
    saveUserContactMessage: message,
  };
};

export const requestFetchUserTransactions = (payload: {
  WalletNumber: string;
  PageNumber: string;
  RecordPerPage: string;
  DateFrom: string;
  DateTo: string;
  Proxy: string;
}) => {
  const { data, isPending, isError, isSuccess, error } = fetchData({
    url: endPoints.GetTransaction,
    payload: payload,
  });

  return {
    allTransactionData: data?.[0] ?? [],
    loadTransaction: isPending,
    isTransactionError: isError,
    isTransactionSuccess: isSuccess,
    transactionError: error,
  };
};

export const requestUserTransactions = () => {
  const {
    data,
    isPending,
    isError,
    isSuccess,
    message,
    error,
    mutate,
    clearData,
  } = postMutate(endPoints.GetTransaction);

  return {
    transactionData: data,
    loadTransaction: isPending,
    isTransactionError: isError,
    isTransactionSuccess: isSuccess,
    transactionMessage: message,
    transactionError: error,
    mutate,
    clearData,
  };
};

export const locateUserAccount = () => {
  const {
    data,
    isPending,
    isError,
    isSuccess,
    message,
    error,
    mutate,
    clearData,
  } = postMutate(endPoints.LocateWallet);
  return {
    locateUserData: data,
    loadLocateUser: isPending,
    isLocateUserError: isError,
    isLocateUserSuccess: isSuccess,
    locateUserMessage: message,
    locateUserError: error,
    mutate,
    clearData,
  };
};

export const confirmWithPIN = () => {
  const {
    data,
    isPending,
    isError,
    isSuccess,
    message,
    error,
    mutate,
    clearData,
  } = postMutate(endPoints.CheckUserPIN);
  return {
    checkUSerPINData: data,
    loadCheckUSerPIN: isPending,
    isCheckUSerPINError: isError,
    isCheckUSerPINSuccess: isSuccess,
    checkUSerPINMessage: message,
    checkUSerPINError: error,
    mutate,
    clearData,
  };
};

export const requestCashBack = () => {
  const {
    data,
    isPending,
    isError,
    isSuccess,
    message,
    error,
    mutate,
    clearData,
  } = postMutate(endPoints.CashBackRequest);
  return {
    cashBackData: data,
    loadCashBack: isPending,
    isCashBackError: isError,
    isCashBackSuccess: isSuccess,
    cashBackMessage: message,
    cashBackError: error,
    mutate,
    clearData,
  };
};

export const uploadSupportedDocument = () => {
  const {
    data,
    isPending,
    isError,
    isSuccess,
    message,
    error,
    mutate,
    clearData,
  } = postMutate(endPoints.UploadKYCDocs);
  return {
    uploadSupportedDocData: data,
    loadUploadSupportedDoc: isPending,
    isUploadSupportedDocError: isError,
    isUploadSupportedDocSuccess: isSuccess,
    uploadSupportedDocMessage: message,
    uploadSupportedDocError: error,
    mutate,
    clearData,
  };
};
