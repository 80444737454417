import { Dropdown, Button, Flex, Image, Avatar } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useLanguage } from 'hooks/Language/useLanguage';
import { Icon } from 'components/shared/Atoms';
import { useState } from 'react';

const LanguageDropdown = () => {
  const { menuProps, currentLang, isLoading, translate } =
    useLanguage();
  const loading = isLoading;

  return (
    <div style={{ width: '150px' }} className="flex-row-layout">
      <Dropdown menu={menuProps} placement="bottom">
        <Button type="text">
          <Flex>
            {(!loading && currentLang?.icon) || ''}
            <Flex gap="small" align="center">
              {!loading && currentLang?.label}
              {loading && <span>{translate('Loading')}...</span>}
              {loading ? (
                <LoadingOutlined />
              ) : (
                <Icon name="language" />
              )}
            </Flex>
          </Flex>
        </Button>
      </Dropdown>
    </div>
  );
};

export const LanguageImg: React.FC<{
  flag: string;
  width?: number;
  name?: string;
}> = ({ flag, width = 20, name }) => {
  const [isError, setIsError] = useState(false);

  const handleError = () => {
    setIsError(true);
  };

  const getInitials = (name?: string) => {
    const words = name?.trim().split(/\s+/);
    if (words?.length === 1) {
      return words[0][0].toUpperCase();
    } else if (words?.length === 2) {
      return `${words?.[0]?.[0].toUpperCase()}${words?.[1]?.[0].toUpperCase()}`;
    } else {
      return `${words?.[0]?.[0].toUpperCase()}${words?.[1]?.[0].toUpperCase()}`;
    }
  };

  return (
    <Flex style={{ margin: '5px' }} align="center">
      {isError ? (
        <Avatar shape="circle" size={width}>
          {getInitials(name)}
        </Avatar>
      ) : (
        <Image
          src={flag}
          width={width}
          onError={handleError}
          alt="Language Flag"
          preview={false}
        />
      )}
    </Flex>
  );
};

export default LanguageDropdown;
