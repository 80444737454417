/* eslint-disable @typescript-eslint/no-explicit-any */
import { ILanguageData } from 'interfaces/queries/language';
import { useState, useEffect } from 'react';

interface LanguageState {
  currentLanguage: string | null;
  error: Error | null;
  savePreferredLang: (_lang: string) => void;
  savePreferredLangData: (_languageData: string) => void;
  languageData?: string;
  currentLanguageData: ILanguageData[] | null;
}

const usePreferredLang = (): LanguageState => {
  const [language, setLanguage] = useState<string | null>(null);
  const [languageData, setLanguageData] = useState<
    ILanguageData[] | null
  >(null);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const fetchPreferred = async () => {
      try {
        const savedLang = localStorage.getItem('currentLanguage');
        const savedLangData: any =
          localStorage.getItem('currentLangData');
        setLanguageData(JSON.parse(savedLangData));
        setLanguage(savedLang);
      } catch (error) {
        setError(error as Error);
      }
    };

    fetchPreferred();
  }, []);

  const savePreferredLang = (lang: string) => {
    try {
      localStorage.setItem('currentLanguage', lang);
      setLanguage(lang);
    } catch (error) {
      setError(error as Error);
    }
  };

  const savePreferredLangData = (languageData: string) => {
    try {
      localStorage.setItem('currentLangData', languageData);
    } catch (error) {
      setError(error as Error);
    }
  };

  return {
    currentLanguage: language,
    error,
    savePreferredLang,
    currentLanguageData: languageData,
    savePreferredLangData,
  };
};

export default usePreferredLang;
