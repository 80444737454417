import { SVGProps } from 'react';
const BugSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fill="currentColor"
        d="M4 17v5H2V3h19.138a.5.5 0 0 1 .435.748L18 10l3.573 6.252a.499.499 0 0 1-.435.748H4ZM4 5v10h14.554l-2.858-5 2.858-5H4Z"
      />
    </g>
  </svg>
);
export default BugSVG;
