/* eslint-disable @typescript-eslint/no-explicit-any */
import apiRequest from 'helpers/apiRequest';

export const getTokenFromLocalStorage = (
  key: string,
): string | null => localStorage.getItem(key);

export const setTokenToLocalStorage = (
  key: string,
  value: string,
): void => localStorage.setItem(key, value);

const refreshAuthToken = async (
  refreshToken: string,
): Promise<string> => {
  const response = await apiRequest({
    url: '/RefreshToken',
    method: 'POST',
    payload: { RefreshToken: refreshToken },
  });
  const { LiveToken, RefreshToken } = response.data[0];

  setTokenToLocalStorage('userToken', LiveToken);
  setTokenToLocalStorage('userRefreshToken', RefreshToken);

  return LiveToken;
};

export const makeApiRequest = async (originalRequest: any) => {
  try {
    const refreshToken = getTokenFromLocalStorage('userRefreshToken');
    if (refreshToken) {
      const newLiveToken = await refreshAuthToken(refreshToken);
      originalRequest.headers.From = newLiveToken;
      return apiRequest(originalRequest);
    }
  } catch (error) {
    handleRequestError(error);
  }
};

const handleRequestError = (error: any) => {
  if (!error.response) {
    throw error;
  }

  const { data } = error.response;
  if (
    data &&
    data[0]?.Description.includes(
      'The Token not found or has been deleted',
    )
  ) {
    logout();
  } else {
    throw error;
  }
};

const logout = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('refresh_token');
  window.location.href = '/login';
};

export const onError = (error: {
  response: { data: unknown };
  message: string;
}) => {
  const errorData: any = error.response?.data;
  if (
    Array.isArray(errorData) ||
    (typeof errorData === 'object' && errorData !== null)
  ) {
    return Promise.resolve(errorData);
  }
  return Promise.resolve(errorData);
};

export const onSuccess = (response: { data: { data: unknown } }) => {
  let formattedData;
  const responseData: any = response?.data;

  if (Array.isArray(responseData)) {
    formattedData = responseData[0];
  } else if (
    typeof responseData === 'object' &&
    responseData !== null
  ) {
    formattedData = responseData;
  }
  return Promise.resolve(formattedData);
};

export const onSuccessFetch = (response: {
  data: { data: unknown };
}) => {
  let formattedData;
  const responseData: any = response?.data;

  if (Array.isArray(responseData)) {
    formattedData = responseData;
  } else if (
    typeof responseData === 'object' &&
    responseData !== null
  ) {
    formattedData = responseData;
  }
  return formattedData;
};

export const requestPayload =
  ({ url, method }: { url?: string; method: string }) =>
  async (payload?: any) => {
    const token = getTokenFromLocalStorage('userRefreshToken');
    const originalRequest = {
      url,
      method,
      payload,
      headers: { From: token },
    };

    const response = await apiRequest(originalRequest);
    return response;
  };
