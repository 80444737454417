import { SVGProps } from 'react';
const CardSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fill="currentColor"
        d="M3 3h18a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1Zm17 8H4v8h16v-8Zm0-2V5H4v4h16Zm-6 6h4v2h-4v-2Z"
      />
    </g>
  </svg>
);
export default CardSVG;
