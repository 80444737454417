import { useQueryClient } from '@tanstack/react-query';
import { notification } from 'antd';
import Toast from 'components/shared/Alert';
import { userLogout } from 'queries/ossix/auth/userLogin';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import urls from 'routes/urls';
import endPoints from 'utils/platformEndPoints/ossix';

export default () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [api, contextHolder] = notification.useNotification();
  const {
    loadLogoutUser,
    isLogoutUserError,
    isLogoutUserSuccess,
    logoutUserMessage,
    mutate: requestLogout,
    clearData: clearLogoutRequest,
  } = userLogout();

  const handleInvalidateCache = () => {
    queryClient.invalidateQueries({
      queryKey: [endPoints.GetUserData],
      exact: true,
    });
  };

  const handleRemoveCache = () => {
    queryClient.removeQueries({
      queryKey: [endPoints.GetUserData],
      exact: true,
    });
  };

  const onHandleLogout = () => {
    requestLogout({});
  };

  useEffect(() => {
    if (isLogoutUserSuccess) {
      localStorage.removeItem('userToken');
      localStorage.removeItem('userRefreshToken');
      clearLogoutRequest();
      handleRemoveCache();
      handleInvalidateCache();
      navigate(urls.login, { replace: true });
    }
    if (isLogoutUserError) {
      Toast(api, 'Error', logoutUserMessage);
      clearLogoutRequest();
    }
  }, [api, logoutUserMessage, isLogoutUserSuccess]);

  return {
    contextHolder,
    onHandleLogout,
    loadLogoutUser,
  };
};
