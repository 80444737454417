export default {
  blackColor: '#000000',
  bronzeColor: '#946e2a',
  DarkGoldenrodColor: '#b48e3d',
  LightGoldColor: '#e6be68',
  paleYellowColor: '#ffec94',
  MustardColor: '#d5aa4c',
  navyBlueColor: '#2c427c',
  whiteColor: '#fff',
  deepGreenColor: '#008000',
  greyColor: '#946E2A',
  $lightNayBlueColor10Percent: '#2c427c1a',
  $lightNayBlueColor25Percent: '#2c427c40',
  $lightNayBlueColor75Percent: '#2c427cbf',
};
