import { SVGProps } from 'react';
const MinusSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    {...props}
  >
    <path fill="currentColor" d="M19 12.998H5v-2h14z"></path>
  </svg>
);
export default MinusSVG;
