import { ReactElement } from 'react';
import { IRoute } from 'interfaces/routesInt';
import urls from '../urls';
import ManageRoutes from 'routes/manageRoute';

const publicPageElement: ReactElement = <ManageRoutes />;

const publicRoutes: IRoute[] = [
  {
    path: urls.landingPage,
    element: publicPageElement,
    isProtected: false,
    key: 'landing-page',
  },
  {
    path: urls.login,
    element: publicPageElement,
    isProtected: false,
    key: 'login',
  },
  {
    path: urls.login,
    element: publicPageElement,
    isProtected: false,
    key: 'login',
  },
  {
    path: urls.register,
    element: publicPageElement,
    isProtected: false,
    key: 'register',
  },
  {
    path: urls.remindUsername,
    element: publicPageElement,
    isProtected: false,
    key: 'remindUsername',
  },
  {
    path: urls.forgotPassword,
    element: publicPageElement,
    isProtected: false,
    key: 'forgotPassword',
  },
];

export default publicRoutes;
