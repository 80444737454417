import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { IPlatformData } from 'interfaces/config/appConfig';
import { AppConfig } from 'utils/config';

export const PlatformContext = createContext<IPlatformData | null>(
  null,
);

const PlatformProvider = ({ children }: { children: ReactNode }) => {
  const [platformConfig, setPlatformConfig] =
    useState<IPlatformData | null>(AppConfig()?.text);

  useEffect(() => {
    const platform = AppConfig();
    setPlatformConfig(platform?.text);
  }, []);

  return (
    <PlatformContext.Provider value={platformConfig}>
      {children}
    </PlatformContext.Provider>
  );
};

export const usePage = (pageKey: string) => {
  const Pages = useMemo(() => {
    return AppConfig()?.pages[pageKey] || null;
  }, [pageKey]);

  return Pages;
};

export const platformRequestOption =
  AppConfig()?.requestOption || null;

export const usePlatformConfig = () => {
  const platformConfig = useContext(PlatformContext);
  if (!platformConfig) {
    throw new Error('Platform configuration not available');
  }
  return platformConfig;
};

export default PlatformProvider;
