import { useQuery } from '@tanstack/react-query';
import apiRequest from 'helpers/apiRequest';
import { onError, onSuccessFetch } from './requestResponse';

/*
 * All these functions can be accessed using the available response keys.
 * data, error, isPending, mutate, data, error, isLoading,....
 */

export function fetchAllData<T>(options: {
  url?: string;
  otherQueryKeys?: T[];
  enabled?: boolean | undefined;
  time?: number;
}) {
  const queryKeys = options.otherQueryKeys || [];

  return useQuery({
    queryKey: [options.url, ...queryKeys],
    staleTime: options.time || 60 * 1000,
    enabled: options.enabled,
    refetchOnMount: false,
    queryFn: () =>
      apiRequest({ url: options.url, method: 'GET' })
        .then(onSuccessFetch)
        .catch(onError),
  });
}

export function fetchData<T>(options: {
  url?: string;
  payload?: T;
  otherQueryKeys?: T[];
  enabled?: boolean | undefined;
  time?: number;
}) {
  const queryKeys = options.otherQueryKeys || [];
  const payload = options.payload;

  return useQuery({
    queryKey: [options.url, ...queryKeys],
    staleTime: options.time || 1000 * 60 * 60,
    enabled: options.enabled,
    refetchOnMount: false,
    queryFn: async () =>
      apiRequest({
        url: options.url,
        method: 'POST',
        payload: payload,
      })
        .then(onSuccessFetch)
        .catch(onError),
  });
}
