import './index.scss';

const ErrorRequestConfirm = () => {
  return (
    <button className="error-button">
      <span className="error-button__icon">✖</span>
    </button>
  );
};

export default ErrorRequestConfirm;
