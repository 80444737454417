import icon from 'assets/icons';
import YesWalletLogo from 'assets/images/yesWalletIcon.svg';

export default {
  icon: icon.YesLogo,
  primaryColor: '#84c34f',
  welcomeLogoWallet: YesWalletLogo,
  welcomeMessage:
    'Unlock the potential of effective money management and take control of your finances.',
};
