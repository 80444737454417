import { postMutate } from 'utils/customMutation';
import { fetchData } from 'utils/customUseQuery';
import endPoints from 'utils/platformEndPoints/ossix';

export const supportedLanguages = (payload) => {
  const { data, isLoading } = fetchData({
    url: endPoints.SupportedLanguages,
    payload: payload,
  });
  return { isLoading, language: data ?? [] };
};

export const loadLanguageData = () => {
  const { pureData, isPending, mutate, isSuccess } = postMutate(
    endPoints.LoadLanguage,
  );
  const languageData = pureData;

  if (Array.isArray(languageData)) {
    return {
      isLoadingLanguage: isPending,
      languageData: languageData || [],
      submitData: mutate,
      isSuccess,
    };
  } else {
    return {
      isLoadingLanguage: isPending,
      languageData: [],
      submitData: mutate,
      isSuccess,
    };
  }
};
