import { SVGProps } from 'react';
const SettingSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fill="currentColor"
        d="M2 11.638c0-.865.11-1.703.316-2.504A3 3 0 0 0 4.99 4.505 9.99 9.99 0 0 1 9.325 2a3 3 0 0 0 5.348 0 9.99 9.99 0 0 1 4.335 2.505 3 3 0 0 0 2.675 4.63 10.036 10.036 0 0 1 0 5.007 3 3 0 0 0-2.675 4.629 9.99 9.99 0 0 1-4.335 2.505 3 3 0 0 0-5.348 0 9.99 9.99 0 0 1-4.335-2.505 3 3 0 0 0-2.675-4.63A10.056 10.056 0 0 1 2 11.638Zm4.804 3c.63 1.091.81 2.346.564 3.524.408.29.842.541 1.297.75A4.993 4.993 0 0 1 12 17.638c1.26 0 2.438.471 3.335 1.274.455-.209.889-.46 1.297-.75a4.993 4.993 0 0 1 .564-3.524 4.993 4.993 0 0 1 2.77-2.25 8.134 8.134 0 0 0 0-1.5 4.993 4.993 0 0 1-2.771-2.25 4.993 4.993 0 0 1-.564-3.524 7.988 7.988 0 0 0-1.297-.75A4.993 4.993 0 0 1 12 5.638a4.993 4.993 0 0 1-3.335-1.274 7.99 7.99 0 0 0-1.297.75 4.993 4.993 0 0 1-.564 3.524 4.993 4.993 0 0 1-2.77 2.25 8.125 8.125 0 0 0 0 1.5 4.993 4.993 0 0 1 2.771 2.25h-.001Zm5.196 0a3 3 0 1 1 0-6 3 3 0 0 1 0 6Zm0-2a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SettingSVG;
