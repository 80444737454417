/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react';

// Define the type for the status state
interface StatusState {
  isPending: boolean;
  isCanceled: boolean;
  isSuccess: boolean;
}

// Custom hook to use the context
export const useStatus = () => {
  const [status, setStatus] = useState<StatusState>({
    isPending: false,
    isCanceled: false,
    isSuccess: false,
  });

  return { status, setStatus };
};
