/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  onError,
  onSuccess,
  onSuccessFetch,
  requestPayload,
} from './requestResponse';

/*
 * All these functions can be accessed using the available response keys.
 * data, error, isPending, mutate,....
 *!!!Please use mutate key function to submit data
 */

type ApiResponse = {
  data?: any;
  error?: any;
};

function extractErrorMessage(
  errorData: ApiResponse['error'],
): string | undefined {
  return errorData[0]?.Description || errorData?.message;
}

function extractData(data: ApiResponse['data']) {
  let formattedData;
  let unformattedData;
  if (Array.isArray(data)) {
    formattedData = data[0];
    unformattedData = data;
  } else if (typeof data === 'object' && data !== null) {
    formattedData = data;
  }
  return { formattedData, unformattedData };
}

function extractErrorData(data: ApiResponse['data']) {
  let formattedData;
  if (Array.isArray(data)) {
    formattedData = data[0];
  } else if (typeof data === 'object' && data !== null) {
    formattedData = data;
  }
  return formattedData;
}

export function postMutate(url?: string) {
  const queryClient = useQueryClient();
  let message: string | undefined;
  let successData;
  let errorResponseData;
  let pureData;

  const {
    data,
    error,
    isError,
    isSuccess,
    isPending,
    mutate,
    reset,
  } = useMutation({
    mutationKey: [url],
    mutationFn: requestPayload({ url: url, method: 'POST' }),
    onSuccess: (response: any) => {
      const formattedData: any = onSuccess(response);
      successData = formattedData;
      message = formattedData?.Description || formattedData?.message;
      queryClient.invalidateQueries({ queryKey: [url], exact: true });
    },
    onError: (error: any) => {
      const errorData = onError(error);
      errorResponseData = errorData;
      message = extractErrorMessage(errorData);
    },
  });

  const errorData: ApiResponse['error'] | undefined =
    error?.response?.data;
  const responseData: ApiResponse['data'] | undefined = data;

  if (errorData) {
    message = extractErrorMessage(errorData);
    errorResponseData = extractErrorData(errorData);
  }
  if (responseData) {
    successData = extractData(responseData?.data).formattedData;
    pureData = extractData(responseData?.data).unformattedData;
    message = successData?.Description || successData?.message;
  }

  return {
    pureData: pureData,
    data: successData,
    error: errorResponseData,
    isPending,
    mutate,
    message,
    isError,
    isSuccess,
    clearData: reset,
  };
}

export function fetchDataByMutate(url?: string) {
  const queryClient = useQueryClient();
  let message: string | undefined;
  let successData;
  let errorResponseData;

  const {
    data,
    error,
    isError,
    isSuccess,
    isPending,
    reset,
    mutate,
  } = useMutation({
    mutationKey: [url],
    mutationFn: requestPayload({ url: url, method: 'POST' }),
    onSuccess: (response: any) => {
      const formattedData = onSuccessFetch(response);
      successData = formattedData;
      message = formattedData?.Description || formattedData?.message;
      queryClient.invalidateQueries({ queryKey: [url], exact: true });
    },
    onError: (error: any) => {
      const errorData = onError(error);
      errorResponseData = errorData;
      message = extractErrorMessage(errorData);
    },
  });

  const errorData: ApiResponse['error'] | undefined =
    error?.response?.data;
  const responseData: ApiResponse['data'] | undefined = data;

  if (errorData) {
    message = extractErrorMessage(errorData);
    errorResponseData = extractErrorData(errorData);
  }
  if (responseData) {
    successData = extractData(responseData?.data).formattedData;
    message = successData?.Description || successData?.message;
  }

  return {
    data: successData,
    error: errorResponseData,
    isPending,
    mutate,
    message,
    isError,
    isSuccess,
    clearData: reset,
  };
}
