/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import { MenuProps } from 'antd';
import {
  loadLanguageData,
  supportedLanguages,
} from 'queries/ossix/languages/fetchSupportedLanguages';
import {
  IComponentProps,
  ILanguage,
  ILanguageData,
} from 'interfaces/queries/language';
import { LanguageImg } from 'components/ossix/LangComponent';
import usePreferredLang from './preferredLang';

import 'dayjs/locale/fr';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import dayjs from 'dayjs';

dayjs.extend(localizedFormat);
dayjs.locale('en');

interface LanguageContextProps {
  translate: (_text?: string, _index?: number | string) => string;
  menuProps: MenuProps;
  currentLang: ICurrentLang;
  isLoading: boolean;
  isLoadingLanguage: boolean;
  currentLanguage: string | null;
  formatDate: (_date: string) => any;
}

interface ICurrentLang {
  key: number | string;
  label: string;
  icon: React.ReactNode;
}

const defaultLang = 'en';
const LanguageContext = createContext<LanguageContextProps | null>(
  null,
);

const LanguageProvider: React.FC<IComponentProps> = ({
  children,
}) => {
  const {
    currentLanguage,
    savePreferredLang,
    currentLanguageData,
    savePreferredLangData,
  } = usePreferredLang();
  const [selectedLanguage, setSelectedLanguage] = useState<string>(
    currentLanguage || defaultLang,
  );

  const { language, isLoading } = supportedLanguages({});

  const {
    isSuccess,
    languageData,
    isLoadingLanguage,
    submitData: requestPayload,
  } = loadLanguageData();

  const handleMenuClick: MenuProps['onClick'] = (value) => {
    savePreferredLang(value.key);
  };

  const items: MenuProps['items'] | undefined = language?.map(
    (item: ILanguage) => ({
      key: item.Code,
      label: item.Name,
      icon: <LanguageImg flag={item.Flag} />,
    }),
  );

  const currentLang: any =
    items && items.find((lang) => lang?.key === selectedLanguage);

  useEffect(() => {
    if (defaultLang != selectedLanguage && selectedLanguage != null) {
      requestPayload({ Language: selectedLanguage });
    }
  }, [selectedLanguage]);

  useEffect(() => {
    const checkDefaultLang = defaultLang != currentLanguage;
    if (checkDefaultLang && isSuccess) {
      const langData = JSON.stringify(languageData);
      savePreferredLangData(langData);
    }

    if (currentLanguage) {
      setSelectedLanguage(currentLanguage);
    }
  }, [currentLang, currentLanguage, languageData]);

  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  const translate = (text = '', index?: number | string): string => {
    if (defaultLang === currentLanguage) {
      return text;
    }

    if (!currentLanguageData) {
      return text;
    }

    try {
      const searchValue = index
        ? Number(index)
        : text.toLowerCase().trim();

      const preferredLangData = currentLanguageData.find(
        (item: ILanguageData) =>
          item.Index === searchValue ||
          item.Default.toLowerCase().trim() === searchValue,
      );

      return preferredLangData?.Label ?? text;
    } catch (error) {
      return text;
    }
  };

  dayjs.extend(localizedFormat);
  dayjs.locale(currentLanguage);

  const formatDate = (dateString: string) => {
    const datePart = dateString?.split(' - ')[0];
    return dayjs(datePart).format('LL');
  };

  return (
    <LanguageContext.Provider
      value={{
        translate,
        menuProps,
        currentLang,
        isLoading,
        isLoadingLanguage,
        currentLanguage,
        formatDate,
      }}
    >
      {children}
    </LanguageContext.Provider>
  );
};

const useLanguage = () => {
  const languageContext = useContext(LanguageContext);
  if (!languageContext) {
    throw new Error('Platform configuration not available');
  }
  return languageContext;
};

export { useLanguage, LanguageProvider };
