import './index.scss';

const SuccessRequestConfirm = () => {
  return (
    <button className="success-button">
      <span className="success-button__icon">✔</span>
    </button>
  );
};

export default SuccessRequestConfirm;
