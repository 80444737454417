import RequestConfirmModal from 'components/shared/RequestConfirm';
import { useLanguage } from 'hooks/Language/useLanguage';
import { getUserData } from 'queries/ossix/user/userData';
import { useEffect, useState } from 'react';
import socketIOClient from './socketIO';
import ErrorRequestConfirm from 'components/shared/RequestConfirm/Error';
import { useStatus } from 'hooks/useStatusProvider';

const OPERATION_FAILED = 'OperationFailed';

export default () => {
  const { translate } = useLanguage();
  const { userData } = getUserData();
  const { status, setStatus } = useStatus();
  const [message, setMessage] = useState<string>('');

  const data = userData?.[0];

  const onHandleUpdateStatus = () => {
    setStatus((prevStatus) => ({
      ...prevStatus,
      isCanceled: true,
      isPending: true,
      isSuccess: false,
    }));
  };

  useEffect(() => {
    if (data && data.PID) {
      socketIOClient.off(OPERATION_FAILED);
      socketIOClient.on(OPERATION_FAILED, (notification) => {
        const { message } = notification || {};
        onHandleUpdateStatus();
        setMessage(message);
      });
    }

    return () => {
      socketIOClient.off(OPERATION_FAILED);
    };
  }, [data && data.PID]);

  useEffect(() => {
    if (status.isCanceled) {
      RequestConfirmModal({
        icon: <ErrorRequestConfirm />,
        translate: translate,
        subtitle: translate(message),
        title: translate('Failed'),
        status,
      });
    }
  }, [status]);
};
