import { PageTypes } from 'types/pageType';
import { lazy } from 'react';

const Dashboard = lazy(() => import('containers/ossix/Dashboard'));
const ForgotPassword = lazy(
  () => import('containers/ossix/auth/ForgotPassword'),
);
const RemindUsername = lazy(
  () => import('containers/ossix/auth/RemindUsername'),
);
const Login = lazy(
  () => import('containers/ossix/auth/LoginPageContainer'),
);
const Registration = lazy(
  () => import('containers/ossix/auth/RegisterPageContainer'),
);

const YesPages: PageTypes = {
  LoginPage: <Login />,
  RegistrationPage: <Registration />,
  DashboardPage: <Dashboard />,
  ForgotPasswordPage: <ForgotPassword />,
  RemindUsernamePage: <RemindUsername />,
};

export default YesPages;
