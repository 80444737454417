import { SVGProps } from 'react';
const MerchantSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fill="currentColor"
        d="M20.18 13.118V19.2h.909V21H2.909v-1.8h.909v-6.082a4.06 4.06 0 0 1-1.335-1.458 4.015 4.015 0 0 1 .092-3.983L5.041 3.45A.905.905 0 0 1 5.828 3H18.17a.916.916 0 0 1 .787.45l2.456 4.214c.543.892.717 1.956.487 2.972a4.046 4.046 0 0 1-1.721 2.482Zm-1.818.657a4.125 4.125 0 0 1-1.849-.217 4.091 4.091 0 0 1-1.56-1.007 4.122 4.122 0 0 1-5.908.002 4.09 4.09 0 0 1-1.56 1.006 4.126 4.126 0 0 1-1.849.217V19.2h12.726V13.775ZM6.353 4.8 4.142 8.592a2.23 2.23 0 0 0-.018 1.642c.203.53.6.965 1.114 1.217a2.293 2.293 0 0 0 1.653.143 2.27 2.27 0 0 0 1.31-1.008c.305-.753 1.382-.753 1.687 0 .169.418.46.776.836 1.029a2.29 2.29 0 0 0 2.55 0c.376-.253.667-.61.836-1.029.304-.753 1.381-.753 1.687 0a2.248 2.248 0 0 0 1.304 1.259 2.293 2.293 0 0 0 2.548-.666 2.243 2.243 0 0 0 .506-1.732 2.236 2.236 0 0 0-.307-.87L17.644 4.8H6.354Z"
      />
    </g>
  </svg>
);
export default MerchantSVG;
