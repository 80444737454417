import { postMutate } from 'utils/customMutation';
import endPoints from 'utils/platformEndPoints/ossix';

export const userLogin = () => {
  const {
    data,
    isPending,
    isError,
    isSuccess,
    message,
    error,
    mutate,
    clearData,
  } = postMutate(endPoints.CheckUserCredential);
  return {
    loginUserData: data,
    loadLoginUser: isPending,
    isLoginUserError: isError,
    isLoginUserSuccess: isSuccess,
    loginUserMessage: message,
    loginUserError: error,
    mutate,
    clearData,
  };
};

export const userLoginStatus = () => {
  const {
    data,
    isPending,
    isError,
    isSuccess,
    message,
    error,
    mutate,
    clearData,
  } = postMutate(endPoints.GetUserLoginStatus);
  return {
    loginStatusData: data,
    loadLoginStatus: isPending,
    isLoginStatusError: isError,
    isLoginStatusSuccess: isSuccess,
    loginStatusMessage: message,
    loginStatusError: error,
    mutate,
    clearData,
  };
};

export const remindUserPID = () => {
  const {
    data,
    isPending,
    isError,
    isSuccess,
    message,
    error,
    mutate,
  } = postMutate(endPoints.GetUserPID);
  return {
    remindUserPIDData: data,
    loadRemindUserPID: isPending,
    isRemindUserPIDError: isError,
    isRemindUserPIDSuccess: isSuccess,
    remindUserPIDMessage: message,
    remindUserPIDError: error,
    mutate,
  };
};

export const checkResetPassword = () => {
  const {
    data,
    isPending,
    isError,
    isSuccess,
    message,
    error,
    mutate,
  } = postMutate(endPoints.ResetPasswordPrequalification);
  return {
    checkResetPasswordData: data,
    loadCheckResetPassword: isPending,
    isCheckResetPasswordError: isError,
    isCheckResetPasswordSuccess: isSuccess,
    checkResetPasswordMessage: message,
    checkResetPasswordError: error,
    mutate,
  };
};

export const userLogout = () => {
  const {
    data,
    isPending,
    isError,
    isSuccess,
    message,
    error,
    mutate,
    clearData,
  } = postMutate(endPoints.LogOff);
  return {
    logoutUserData: data,
    loadLogoutUser: isPending,
    isLogoutUserError: isError,
    isLogoutUserSuccess: isSuccess,
    logoutUserMessage: message,
    logoutUserError: error,
    mutate,
    clearData,
  };
};
