import { usePlatformConfig } from 'hooks/PlatformContext';
import './index.scss';

const LoadingComponent = () => {
  const platformConfig = usePlatformConfig();
  const logo = platformConfig.icon;
  return (
    <div className="loading-container">
      <img src={logo} className="loading-svg" />
    </div>
  );
};

export default LoadingComponent;
