import { IGenerateRoute } from 'interfaces/routesInt';
import PageNotFound from 'components/shared/Error';

const GenerateRoutes = ({
  splitLocation,
  pageRoute,
}: IGenerateRoute) => {
  const routeName = splitLocation.segments[1];
  const page = pageRoute[routeName];

  if (!page) {
    return <PageNotFound />;
  }
  return page;
};

export default GenerateRoutes;
