import { Button, Flex, Modal } from 'antd';
import agbColors from 'constants/Colors/agbColors';
import urls from 'routes/urls';

const RequestConfirmModal = ({
  translate,
  icon,
  title,
  subtitle,
  status,
}) => {
  const showConfirm = () => {
    const modal = Modal.confirm({
      icon: null,
      footer: null,
      content: (
        <Flex
          className="xxlarge-padding-top"
          gap="small"
          align="center"
          vertical
        >
          {icon}
          <span className="xlarge-margin-top medium-text">
            {title}
          </span>
          <span>{subtitle}</span>
          <Button
            className="xlarge-margin-top"
            type="primary"
            onClick={() => {
              if (status.isPending) {
                modal.destroy();
                window.location.href = urls.transactions;
              }
              if (status.isCanceled) {
                modal.destroy();
                window.location.href = urls.transactions;
              }
              if (status.isSuccess) {
                modal.destroy();
                window.location.href = urls.transactions;
              }
            }}
            style={{
              width: '200px',
              background: agbColors.navyBlueColor,
            }}
          >
            {translate('Ok')}
          </Button>
        </Flex>
      ),
    });
  };

  showConfirm();
  return null;
};

export default RequestConfirmModal;
